import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout/';
import ArticleFooter from '../components/ArticleFooter/';

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "dddd Do MMMM YYYY")
            }
        }
    }
`;

export default class BlogPostTemplate extends React.Component {

    post () {
        return this.props.data.markdownRemark;
    }

    render () {
        const { html, frontmatter } = this.post();
        const { title, date }       = frontmatter;
        return (
            <Layout title={title}>
                <article>

                    <header>

                        <h1>{title}</h1>

                        <aside>
                            <p>Posted on {date}.</p>
                        </aside>

                    </header>

                    <section dangerouslySetInnerHTML={{ __html: html }} />

                    <ArticleFooter />

                    <p>Fancy reading more? <Link to='/blog'>See more blog posts</Link>.</p>

                </article>

            </Layout>
        );
    }

}
